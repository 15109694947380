<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.TAG_MANAGEMENT') }}
    </p>
    <div class="sm-content">
      <div class="d-flex">
        <p class="weight-700 filter-name">{{ $t('MANAGEMENT.FILTER_NAME') }}</p>
        <div class="tag-input">
          <label>{{ $t('MANAGEMENT.TAG_TYPE') }}</label>
          <input type="text" class="input" v-model="tagType">
        </div>
        <div class="tag-input mx20">
          <label>{{ $t('MANAGEMENT.TAG_NAME') }}</label>
          <input type="text" class="input">
        </div>
        <button class="button filter__button" @click="filter()"></button>
      </div>
      <v-data-table
        :headers="headers"
        :items="tags"
        class="cus-table padding-table main-table m-t-20"
        item-key="id"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="loading==false && tags.length==0">
            <tr>
              <td colspan="7" class="text-center"> 
                There is no data.
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr 
              v-for="item in items"
              :key="item.id"
            >
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                  {{ item.type }}
                </div>
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                  {{ item.name }}
                </div>
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                  <v-checkbox 
                    color="#0D3856" 
                    class="cus-checkbox" 
                    style="padding: 0;"
                    v-model="item.show_on_product"
                  ></v-checkbox>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                  {{ item.order }}
                </div>  
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                  <v-checkbox 
                    color="#0D3856" 
                    class="cus-checkbox" 
                    style="padding: 0;"
                    :v-model="true"
                  ></v-checkbox>
                </div>
              </td>
              <td>  
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                   <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }"> 
                      <router-link :to="{name: 'SCTagEdit', params: {id: item.id}}">
                        <img src="/assets/img/icons/edit.svg" 
                          alt="" 
                          v-bind="attrs" 
                          v-on="on" 
                          class="edit-icon pointer" 
                        />
                      </router-link>
                    </template>
                    <span>{{ $t("COMMON.EDIT") }}</span>
                  </v-tooltip>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center align-items-center h-100" :style="{'background-color': rowBgColor[item.id%4]}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="red-close_btn pointer" v-bind="attrs" v-on="on" @click="deleteOneTag(item.id)">
                        <img src="/assets/img/icons/cross-red.svg" alt=""/>
                      </div>
                    </template>
                    <span>{{ $t("COMMON.DELETE") }}</span>
                  </v-tooltip>
                </div>  
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-flex m-t-15">
        <router-link to="/management/sc/tag-management/edit-category" class="primary-btn primary-btn_sm bg-yellow color-white">
          {{ $t("MANAGEMENT.CREATE_NEW_TYPE") }}
        </router-link>
        <router-link to="/management/sc/tag-management/edit-tag" class="primary-btn primary-btn_sm bg-green4 mx10 color-white">
          {{ $t("MANAGEMENT.CREATE_NEW_TAG") }}
         </router-link>
      </div>
    </div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'SCTagmanagement',
  data() {
    return {
      headers: [
        {
          text: this.$t('MANAGE_SHANIV.CATEGORY'),
          align: 'center',
          sortable: false,
          value: 'type',
        },
        {
          text: this.$t('MANAGEMENT.TAG_NAME'),
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('MANAGEMENT.APPEARS_ON_PRODUCT'),
          align: 'center',
          sortable: false,
          value: 'show_on_product',
        },
        {
          text: this.$t('DESTINATION.ORDER'),
          align: 'center',
          sortable: false,
          value: 'order',
        },
        {
          text: this.$t('COMMON.ACTIVE'),
          align: 'center',
          sortable: false,
          value: 'active',
        },
        {
          text: this.$t('ADS_MANAGEMENT.EDITING'),
          align: 'center',
          sortable: false,
          value: 'editing',
        },
        {
          text: this.$t('MANAGEMENT.DELETION'),
          align: 'center',
          sortable: false,
          value: 'deletion',
        }
      ],
      rowBgColor: [
        '#D4E2FF', '#FED4FF', '#D4FFD5', '#D4FFF8'
      ],
      tagType: ""
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.tag_management.loading,
      tags: state => state.tag_management.tags,
    }),
  },
  created () {
    // if (this.tags.length == 0) {
      this.getTags();
    // }
  },
  methods: {
    ...mapActions('tag_management', {
      getTags: 'getTags',
      deleteTag: 'deleteTag',
      filterTag: 'filterTag'
    }),
    deleteOneTag(id) {
      this.deleteTag({id: id});
    },
    filter() {
      this.filterTag({type: this.tagType})
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-input {
  max-width: 200px;
}
.filter__button {
  margin-top: 27px;
}
.filter-name {
  margin-top: 32px;
  margin-left: 20px;
}
.padding-table.v-data-table tbody tr td {
  padding: 3px 0;
}
</style>