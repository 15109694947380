var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"color-primary h-100 page_bg_grey blank-aside-js",class:(_vm.isOpenAside=='true')?'blank-aside':''},[_c('p',{staticClass:"main-title weight-700"},[_vm._v(" "+_vm._s(_vm.$t('MANAGEMENT.TAG_MANAGEMENT'))+" ")]),_c('div',{staticClass:"sm-content"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"weight-700 filter-name"},[_vm._v(_vm._s(_vm.$t('MANAGEMENT.FILTER_NAME')))]),_c('div',{staticClass:"tag-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('MANAGEMENT.TAG_TYPE')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tagType),expression:"tagType"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.tagType)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tagType=$event.target.value}}})]),_c('div',{staticClass:"tag-input mx20"},[_c('label',[_vm._v(_vm._s(_vm.$t('MANAGEMENT.TAG_NAME')))]),_c('input',{staticClass:"input",attrs:{"type":"text"}})]),_c('button',{staticClass:"button filter__button",on:{"click":function($event){return _vm.filter()}}})]),_c('v-data-table',{staticClass:"cus-table padding-table main-table m-t-20",attrs:{"headers":_vm.headers,"items":_vm.tags,"item-key":"id"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.loading==false && _vm.tags.length==0)?_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_vm._v(" There is no data. ")])])]):_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_c('v-checkbox',{staticClass:"cus-checkbox",staticStyle:{"padding":"0"},attrs:{"color":"#0D3856"},model:{value:(item.show_on_product),callback:function ($$v) {_vm.$set(item, "show_on_product", $$v)},expression:"item.show_on_product"}})],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_vm._v(" "+_vm._s(item.order)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_c('v-checkbox',{staticClass:"cus-checkbox",staticStyle:{"padding":"0"},attrs:{"color":"#0D3856","v-model":true}})],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{name: 'SCTagEdit', params: {id: item.id}}}},[_c('img',_vm._g(_vm._b({staticClass:"edit-icon pointer",attrs:{"src":"/assets/img/icons/edit.svg","alt":""}},'img',attrs,false),on))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.EDIT")))])])],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100",style:({'background-color': _vm.rowBgColor[item.id%4]})},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"red-close_btn pointer",on:{"click":function($event){return _vm.deleteOneTag(item.id)}}},'div',attrs,false),on),[_c('img',{attrs:{"src":"/assets/img/icons/cross-red.svg","alt":""}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.DELETE")))])])],1)])])}),0)]}}])}),_c('div',{staticClass:"d-flex m-t-15"},[_c('router-link',{staticClass:"primary-btn primary-btn_sm bg-yellow color-white",attrs:{"to":"/management/sc/tag-management/edit-category"}},[_vm._v(" "+_vm._s(_vm.$t("MANAGEMENT.CREATE_NEW_TYPE"))+" ")]),_c('router-link',{staticClass:"primary-btn primary-btn_sm bg-green4 mx10 color-white",attrs:{"to":"/management/sc/tag-management/edit-tag"}},[_vm._v(" "+_vm._s(_vm.$t("MANAGEMENT.CREATE_NEW_TAG"))+" ")])],1)],1),_c('loading',{attrs:{"active":_vm.loading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.loading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }